<template>
    <div style="width:100%" class="acBody">
        <app-image></app-image>
        <div class="localDiv">
            <div class="localContent">
                当前位置：网站首页 / <span>联系我们</span>
            </div>
        </div>
        <div class="acMenuDiv" id="showLocation">
            <p>联系我们</p>
   
        </div>
        <div class="container">
            <div class="container1">
                <div class="aconDiv">
                    <div class="acTitle">酷鹏凯迪科技（北京）有限公司</div>
                    <div id="containerDiv"></div>
                    <div class="acContent">
                        <p  class="div1">酷鹏凯迪科技（北京）有限公司位于北京市朝阳区，注册资金1100万，我们致力于服务大中型企业信息化建设，IT应用软件相关服务！</p>
                        <div >
                            <div class="div2">地址：</div>
                            <p  class="div3">北京市朝阳区望京街9号商业楼3层321号385室</p>
                            <div class="div2">联系方式：</div>
                            <p  class="div3">手机：+86 189 0102 0002 <span></span>电话：+86 010-84351553</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppImage from '../components/common/image.vue'
export default {
    name:"aboutContact",
    components:{AppImage},
    data(){
        return{
          
        }
    },
    methods:{
        init(){
            this.$nextTick(function(){
                document.querySelector('#showLocation').scrollIntoView(true);

            })
            var map = new AMap.Map("containerDiv", {
                resizeEnable: true,
                center: [116.483305,39.989022],
                zoom: 13
            });
        var marker = new AMap.Marker({
            position: new AMap.LngLat(116.483305,39.989062),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        });
        map.add(marker);
      //构建信息窗体中显示的内容
        var info = [];
        info.push("<div><div style=\"font-size:16px;font-weight:bold;color:#935304;width:400px\">酷鹏凯迪科技（北京）有限公司</div> ");
        info.push("<div style=\"padding:0px 0px 0px 4px;font-size:14px;\"><b>酷鹏凯迪科技（北京）有限公司位于北京市朝阳区，注册资金1100万，我们致力于服务大中型企业信息化建设，IT应用软件相关服务！</b>");
        var infoWindow = new AMap.InfoWindow({
           anchor: 'top-left',
            content: info.join("<br/>")  //使用默认信息窗体框样式，显示信息内容
        });
        infoWindow.open(map, map.getCenter());
        }
    },
    mounted(){
        this.init()
    }
}
</script>
<style>
.localDiv{
    background-color:#F8FAFD;
}
.localContent{
    text-align:right;
    padding:20px 0;
    font-size:15px;
    line-height:15px;
    background-color:#F8FAFD;
    width:70%;
    margin:0 auto;
    
}
.localContent span{
    color:#0665f3
}

.acBody .acMenuDiv {
    font-size:30px;
    padding-top:60px;
    text-align:center;
    /* border-bottom:1px solid #DBE3EF; */
    /* box-shadow: 1px 1px 5px #dbe3ef; */
}
.acBody .container{
    background-image:url(../static/about_bg1.png);
    background-repeat:no-repeat;
    background-position: bottom;
}
.acBody .container .container1{
    width:70%;
    margin:0 auto;
}
.acBody .aconDiv{
    padding:60px 0;
}
.acBody #containerDiv{
    width:100%;
    height:400px;
    margin:30px 0;
}
.acBody .acTitle{
    font-size:18px;
    color:#404756;
    height:32px;
    line-height:32px;
    font-weight:bold
}
.acBody .acContent .div1{
    color:#404756;
    line-height:24px;
}
.acBody .acContent .div2{
    margin-top:30px;
    color:#404756;
    font-weight:bold;
    line-height:16px;
}
.acBody .acContent .div3{
    font-size: 16px;
    color:#848484;
}
.acBody .acContent .div3 span{
    width:30px;
    display:inline-block
}
</style>
